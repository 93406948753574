@import '../../../theme/main';

.pluLegend {
  @include environment-container;

  &.noPlu {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0 0 20px 0;
    }
  }
  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-top: 20px;
  }

  .pluButton {
    margin-top: 50px;
    width: 100%;
  }
}
