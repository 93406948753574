@import '../../../theme/main';

.folder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .folderHeader {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-top: 5px;
    border-radius: 5px;
    border-left: #980808 4px solid;
    background-color: $white;
    box-shadow: 0 0 4px rgba(37, 38, 94, 0.1);

    .headerLibelle {
      position: absolute;
      padding-left: 5px;
      font-style: italic;
      font-size: 0.7em;
      top: 0;
      left: 0;
    }

    &.subfolder {
      background-color: #dfdede;
      margin-left: 20px;
      width: calc(100% - 20px);
    }

    .index {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      border-radius: 50%;
      background-color: $color-primary;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      width: 20px;
      height: 20px;
    }

    .iconContainer {
      display: none;

      &.isDashboard {
        display: block;
        position: absolute;
        right: 0px;
        height: 60px;
      }
    }

    &:hover {
      .iconContainer {
        display: block;
        position: absolute;
        right: 0px;
        height: 60px;
      }
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    width: 360px;

    .title {
      color: $color-primary;
      margin: 0;
      margin-bottom: 2px;
    }

    .subCount {
      font-style: italic;
      font-size: 0.8em;
    }
  }

  .folderContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
  }
}
