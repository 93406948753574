@import '../../../../../theme/main';

/* CSS : Raw */

#profileElevationRaw {
  resize: none;
  padding: unset;
}

/* CSS : default */

#profileElevationByDefault {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  // height: 100%;
  // width: 100%;
}

.altiPathValue {
  font-weight: 700;
  fill: #5e5e5e;
}

.altiPathCoords {
  font-style: italic;
  fill: #5e5e5e;
}

.tooltipInit {
  opacity: 0;
}

.tooltipFadeIn {
  transition: opacity 300ms ease 0ms, transform 50ms ease 0s, top 50ms ease 0s,
    left 50ms ease 0s;
  opacity: 1;
}

.tooltipFadeOut {
  transition: opacity 500ms ease 0ms;
  opacity: 0;
}

/* CSS : D3 */

.axis-d3 > text {
  fill: rgb(94, 94, 94);
  font-family: Verdana;
  font-size: 10px;
  opacity: 1;
  text-anchor: end;
}

.axis-d3 {
  fill: none;
  stroke: #5e5e5e;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.area-d3 {
  fill: #c77a04;
  stroke: #5e5e5e;
  stroke-width: 0;
  fill-opacity: 0.4;
}

.line-d3 {
  fill: none;
  stroke: #c77a04;
  stroke-width: 1px;
}

.grid-d3 .tick {
  stroke: lightgrey;
  opacity: 0.7;
}

.grid-d3 path {
  stroke-width: 0;
}

.grid-d3 .tick {
  stroke: lightgrey;
  opacity: 0.7;
}

.grid-d3 path {
  stroke-width: 0;
}

.overlay-d3 {
  fill: none;
  pointer-events: all;
}

.focusLine-d3 {
  fill: none;
  stroke: #c77a04;
  stroke-width: 0.5px;
}

.focusCircle-d3 {
  fill: #c77a04;
}

div.tooltip-d3 {
  position: inherit;
  text-align: left;
  width: 80px;
  height: 45px;
  padding: 5px;
  font: 8px sans-serif;
  background: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
