@import '../../../theme/main';

.filterSortBloc {
  height: 60px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  position: relative;

  .genericSearch {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;

    .MuiInputBase-input {
      height: 22px;
    }

    .inputResearch {
      margin-right: 10px;
    }

    .researchBtn {
      width: 150px;
    }
  }
}
.rightBloc {
  display: flex;
  position: absolute;
  width: 234px;
  right: 0;
  text-align: start;
}
.sortSelect {
  flex-grow: 1;
}

.sortButtons {
  display: flex;
  flex-direction: column;
  width: 30px;
  padding: 0 0 0 10px;

  button {
    border: 0;
    padding: 0;
    .icon {
      @include flex-center-center;
      height: 20px;
      background-color: $light-gray-bg;
      color: $gray;
      width: 100%;
      cursor: pointer;

      &.selected {
        color: $black;
      }

      &.disabled {
        opacity: 20%;
        cursor: default;
      }

      &.desc {
        transform: rotate(180deg);
      }
    }
  }
}
