@import '../../theme/main';

.delete-modal {
  .MuiPaper-rounded {
    // border-radius: 0;
  }

  &-container {
    color: $color-primary;
    border: 6px solid $color-primary;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .align-left {
      text-align: left;
      line-height: 1.4;
    }

    .xicon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;

      img {
        width: 20px;
      }
    }

    &-title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-top: 15px;
      letter-spacing: 0.27px;
    }

    &-content {
      width: 100%;
      margin-top: 30px;

      p {
        margin-bottom: 30px;
        text-align: center;
        font-size: 1.2em;
        span {
          font-weight: 600;
        }
      }

      button {
        border: 0px;
      }
    }
  }
}
