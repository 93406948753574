@import '../../theme/main';

.itemActions {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  height: 60px;
  background-color: $white;

  .genericIcon {
    background: $color-primary;
    color: $white;
    width: 37px;
    height: 37px;
    padding: 8px;
    border-radius: 50%;
    margin: 0px 5px;
    cursor: pointer;

    &:hover {
      background-color: $prospection-icon-primary-hovered;
    }

    &.delete {
      background: $color-warning;

      &:hover {
        background-color: $prospection-icon-danger-hovered;
      }
    }
  }

  .options {
    display: flex;

    &.displayNone {
      display: none;
    }

    .optionsIconContainer {
      display: none;
    }

    &:hover {
      .optionsIconContainer {
        display: flex;

        .add,
        .edit {
          background: $color-warning;

          &:hover {
            background-color: #980808;
          }
        }
      }
    }
  }

  .dropDownIcon {
    color: $color-primary;
    padding: 6px;
    cursor: pointer;

    &.close {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
