@import '../../../theme/variables';
.connector-tab {
  font-size: 16px;
  color: $color-primary;
  text-align: center;

  p {
    margin: 0;
  }

  .connector-lock-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 40px;

    img {
      position: absolute;
      height: 40px;
      margin: auto;
    }

    .connector-border-line {
      width: 100%;
      border: 0.5px dashed $color-primary;
    }
  }

  .connector-text {
    line-height: 19px;
    margin-top: 20px;
  }

  .connector-conneter-btn {
    width: 333px;
    margin-top: 20px;
    font-size: 16px;
  }

  .low-police {
    font-size: 14px;
    line-height: 18px;

    .connector-bottom-text {
      margin-top: 20px;
    }

    .connector-red-text {
      cursor: pointer;
      color: $dark-red;
      text-decoration: underline;
    }
  }
}
