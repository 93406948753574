@import '../../../theme/main';

.pluOtherContainer {
  @include environment-container;

  .pluOtherRow {
    span {
      font-size: 14px;
      padding: 2px;
    }
  }
}
