@import '../../../../../../theme/main';

.geography {
  .title {
    @include flex-between-center;

    div {
      @include flex-center-center;

      svg {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }

      .urbaGptBtn {
        border-radius: 24px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        background-color: $color-warning;
        padding: 0 15px;
        margin-right: 10px;
        height: 35px;
        border: none;
        color: $white;
        cursor: pointer;
        font-weight: 600;

        &Disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }

  ul {
    padding-left: 25px;
    color: $light-black;
    font-size: 14px;
    font-weight: 600;
    margin-left: 0;
    margin-top: 10px;

    li {
      padding: 5px 0px;

      span {
        font-weight: 400;
      }
    }

    .cadastre-link {
      display: flex;
      flex-direction: column;

      span {
        font-style: italic;
        font-weight: 400;
        font-size: 80%;
      }
    }
  }

  .buildingContainer {
    h3 {
      color: $color-primary;
      margin-bottom: 10px;
    }

    button {
      width: 210px;
      height: 30px;
    }

    .buildingRow {
      @include flex-center-center;

      .rowIndex {
        width: 40px;
        font-weight: 700;

        span {
          padding: 1px 5px;
          border-radius: 50%;
          border: 1px solid $color-primary;

          &.maxRound {
            padding: 3px 3px;
          }
        }
      }

      ul {
        flex-grow: 1;
        list-style-type: none;
        padding-left: 0;
        .height-error-info {
          font-size: 11px;
          font-style: italic;
        }
      }
    }
  }
}
