@import './../../../../../theme/main';

.contact-content {
  .contact-list {
    @include flex-column-center-center;

    .contact-card {
      @include flex-between-start;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 15px;

      .left-block {
        width: 30%;
        border-left: 5px solid $grey-prospection-card;
        padding-left: 5px;

        p {
          margin: 0;
          margin-bottom: 5px;
        }

        a {
          color: #4e55a2;
        }
      }

      .center-block {
        margin: 0 10px;
        width: 40%;
      }

      .right-block {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-self: stretch;

        .button-contact-actions {
          display: flex;
          justify-content: flex-end;
          img {
            height: 22px;
            margin: 0 5px;
            cursor: pointer;
          }
        }
        .urba-gpt {
          @include flex-center-center;
          padding-top: 20px;
          &-logo {
            position: absolute;
            right: 0;
            width: 48px;
            height: 48px;
            margin-top: 19px;
          }
          &-btn-logo {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            color: $white;
            background: $color-warning;
            padding: 10px;
            padding-right: 20px;
            margin-left: 5px;
            width: auto;
            min-width: 265px;
            border-radius: 5px;
            position: relative;
            margin-top: 10px;
          }
          &-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            color: $white;
            background: $color-warning;
            padding: 10px;
            margin-left: 5px;
            width: auto;
            border-radius: 5px;
          }
          &-btn-disabled {
            opacity: 0.6;
            cursor: default;
          }
          .arrow-back {
            color: $color-warning;
            cursor: pointer;
          }
        }
      }
    }
  }
  .contact-form {
    .Mui-error {
      fieldset {
        border-color: $color-warning;
      }
    }
    > div {
      margin: 15px 0;
    }

    p {
      margin: 3px 0 0 5px;
      color: $color-warning;
      font-size: 0.75rem;
    }
    .top-block {
      @include flex-between-start;

      > div {
        width: 24%;
      }
    }

    .center-block {
      @include flex-between-start;

      > div {
        width: 45%;
      }

      // p {
      //   margin: 8px 0 0 15px;
      //   color: $color-warning;
      //   font-size: 0.75rem;
      // }
    }

    .bottom-block {
      .contact-infos {
        &:focus-visible {
          outline: none;
        }

        width: 100%;
        padding: 10px;
        background-color: white;
        border: 1px solid grey;
        border-radius: 4px;
      }
    }
  }
}
