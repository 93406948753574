@import '../../theme/main';
.right-panel-modal {
  font-family: $regular-fonts;
  position: absolute;
  right: -850px;
  top: 0;
  z-index: 999;
  height: 100vh;
  width: 800px;
  background-color: $white;
  transition: all 0.5s ease;
  background-image: url('../../assets/images/bg_right_pan.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-left: 1px solid $color-primary;
  border-radius: 10px;
  overflow: hidden;

  textarea {
    font-family: $regular-fonts;
  }

  &.displayed {
    right: 0;
  }

  &-body {
    height: calc(100vh - 180px);
    overflow-y: auto;
    padding: 20px 10px;

    .accordion-summary {
      background-color: $divider;
      color: $color-primary;
      font-size: 16px;
      font-weight: 600;
    }

    .built-permis-body {
      height: calc(99vh - 290px);
      overflow-y: auto;

      .MuiTableCell-root {
        color: #000;
        line-height: 1rem;
        padding: 6px 4px;
        margin: 0;
        text-align: center;
        border: 1px solid #000;
      }
      .table-head {
        background-color: $color-primary;

        .MuiTableCell-root {
          color: #fff;
          font-weight: 700;

          .MuiTableSortLabel-root.MuiTableSortLabel-active {
            color: #fff;
            font-weight: 700;
          }

          .MuiSvgIcon-root {
            fill: $white;
          }
        }
      }
    }

    .panel-content-body {
      color: $color-primary;

      h2 {
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 30px;
      }

      a {
        margin: 0;
        color: $link-color-blue;
        text-decoration: none;
        font-weight: 600;
      }

      .synthese-plots {
        .plot-synthese-table {
          .MuiTableHead-root {
            background-color: #aaa;
            font-weight: 700;
            .MuiTableCell-root {
              font-size: 12px;
              font-weight: 700;
              text-align: center;
            }
          }
          .MuiTableBody-root {
            .MuiTableCell-root {
              font-size: 12px;
              &.city-cell {
                background-color: #ccc;
                font-weight: 700;
              }
            }
          }
        }

        > p {
          font-size: 1.17em;
          // font-weight: 700;
          margin: 3px;
        }
        .not-found-plot {
          font-size: 1em;
          margin: 10px 0;

          span {
            font-size: 1.1em;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }

      .urbanisme-body {
        &-title {
          @include flex-between-center;
        }
        .no-zone {
          p {
            &:first-child {
              font-weight: 700;
            }

            span {
              font-weight: 700;
            }
          }
        }
        .plot-list {
          margin-bottom: 20px;
          p {
            margin: 3px;
          }
        }

        .urbanisme-accordion {
          .accordion-detail {
            display: flex;
            flex-direction: column;

            a {
              color: #666;
              padding: 5px;
            }
          }
          .urbanisme-summary-servitudes {
            background-color: red;
          }
        }

        button {
          margin-top: -20px;
          padding: 0 20px;
        }
      }

      .parcelle-infos-body {
        .accordion-detail {
          h2 {
            font-size: 16px;
            text-align: center;
            margin-bottom: 30px;
          }

          .no-transaction-message {
            text-align: center;
            margin-bottom: 30px;
          }

          .parcelle-display {
            display: flex;
            text-align: center;
            justify-content: center;
            color: $color-primary;
          }
          .transaction-container-no-data {
            text-align: center;
          }
          .transaction-container {
            display: flex;
            flex-wrap: wrap;
            margin: 10px 0;

            .transaction {
              width: 45%;
              margin: 10px 0;

              p {
                font-weight: 700;
                margin: 4px 0;

                span {
                  font-weight: 400;
                }
              }

              .lot-container {
                display: flex;

                > p {
                  text-align: center;
                  border: 1px solid black;
                  border-right: none;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 5px 2px;
                  width: 20%;
                }

                p:last-child {
                  border-right: 1px solid black;
                }
              }
            }
          }

          .owner-informations {
            .informations-links {
              margin-bottom: 0;
              display: flex;
              flex-direction: column;
              font-weight: 600;

              > a {
                margin: 0;
                margin-left: 10px;
              }

              .email {
                display: flex;
                align-items: center;

                img {
                  height: 40px;
                  width: 40px;
                }

                a {
                  color: #000;
                }

                .cerfa {
                  color: $link-color-blue;
                }
              }
            }

            .owner-list {
              margin-left: 10px;
              font-weight: 600;
              color: #424242;

              .owner {
                margin: 20px 0;

                .siren {
                  cursor: pointer;
                  text-decoration: underline;
                  color: $link-color-blue;
                  font-weight: 600;
                }
              }

              p {
                margin: 2px 0;

                span {
                  font-weight: 400;
                }
              }
            }
          }

          .env-risks {
            p {
              font-weight: 600;
              margin: 10px 0;

              span {
                font-weight: 400;
              }
            }
            .georisque-link {
              span {
                font-size: 80%;
                font-style: italic;
              }
            }
          }

          .fiscal-zone {
            p {
              font-weight: 600;
              margin: 10px 0;
              span {
                font-weight: 400;
              }
            }
          }

          .communal-taxes {
            p {
              font-weight: 600;
              margin: 10px 0;

              span {
                font-weight: 400;
              }
            }
          }

          .town-hall {
            @include parcel-info-accordion;
          }

          .balance-bank-accounts {
            @include parcel-info-accordion;
          }
        }
      }
    }
  }

  .arrow-wrapper {
    position: fixed;
    z-index: 1;
    margin-top: 95px;
    cursor: pointer;
    img {
      height: 30px;
      position: absolute;
      left: -28px;
    }
  }
}
