@import '../../../../../theme/main';

.sectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .containerLabel {
    color: $dark-red;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }
}
