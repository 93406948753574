@import 'main';
@import './fonts.scss';

* {
  box-sizing: border-box;
  font-family: $regular-fonts;
}

body {
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $base-font-color;
  font-family: $regular-fonts;
  font-size: $regular-fonts-size;
  line-height: $base-line-height;
  // fix modal overflow: hidden on body when exiting
  // overflow: scroll !important;
}

main {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.button-base {
  cursor: pointer;
  background-color: $color-primary;
  color: $white;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
}

.green-submit-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #75cc8b;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  width: 237px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.drawer-button {
  border-color: $color-primary;
  background-color: $color-primary;
  cursor: pointer;
  border-radius: 2px;
  height: 40px;
  color: $white;
  font-size: 16px;
  font-weight: bold;
  font-family: $primary-font;
  margin-top: 10px;
  width: 100%;
}

.warning-button {
  background-color: $dark-red;
  border-color: $dark-red;
  cursor: pointer;
  border-radius: 2px;
  height: 40px;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  // outline: none;
  border: 0px;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
}
