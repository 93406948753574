@import '../../../theme/main';

.tab-subscription {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 42px;
    color: #353b7d;
    margin-top: 0;
  }
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 24px;
    color: #555980;
    margin-bottom: 25px;
  }
  .tab-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .subscription-card {
      color: #555980;
      background: #ffffff;
      border: 1px solid #d8e3f2;
      box-sizing: border-box;
      margin-bottom: 26px;
      padding: 30px;

      h2 {
        margin-top: 5px;
      }
      ul {
        padding-left: 20px;
      }
      &-body {
        .infos {
          display: flex;
        }
        .sec {
          width: 400px;
          p {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
          strong {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }
          .read-more {
            display: flex;
            justify-content: center;
            letter-spacing: 2px;
            padding: 0 2px;
            border: 1px solid $color-primary;
            border-radius: 7px;
            margin-left: 5px;
            width: 30px;
            height: 20px;
            cursor: pointer;
            font-size: 16px;
          }
          .flex-div {
            display: flex;
          }
          .city-name {
            span {
              text-transform: lowercase;
            }
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
