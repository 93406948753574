@import '../../theme/main';

.genericPopup {
  h2 {
    text-align: center;
  }

  &Tabs {
    @include flex-center-center;
    // border: 1px solid $color-primary;
    border-bottom: none;
    cursor: pointer;

    > p {
      padding: 0;
      margin: 0;
      width: 15px;
      text-align: center;
      font-weight: 700;
      font-size: 1.2em;
      line-height: 36px;
      background-color: lightgrey;
      border: 1px solid $color-primary;

      cursor: pointer;

      &:first-child {
        border-right: none;
      }

      &:last-child {
        border-left: none;
      }

      &.disabled {
        color: white;
      }
    }

    > div {
      flex-grow: 1;
      @include flex-center-center;
      border: 1px solid $color-primary;

      p {
        width: 100%;
        margin: 0;
        padding: 8px 0;
        text-align: center;
        font-weight: 700;
        font-size: 1.2em;
        border-right: 1px solid $color-primary;

        &.selected {
          background-color: $color-primary;
          color: $white;
        }

        &:last-child {
          border-right: none;
          border-left: none;
        }
      }
    }
  }

  .content {
    border: 1px solid $color-primary;
    border-top: none;
    padding: 10px;

    p,
    li {
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-left: 10px;
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0;
      padding: 1px;
    }
  }
}
