@import '../../../theme/_main.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 55px;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  .logo {
    height: 40px;
    margin-left: 50px;
    cursor: pointer;
  }
  button {
    @include flex-center-center;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    background: #d2112f;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    margin-right: 50px;
    padding: 0 20px;
    svg {
      fill: #ffffff;
      margin-left: 10px;
    }
    &:hover {
      color: #d2112f;
      border: 1px #d2112f solid;
      background: #ffffff;
      svg {
        fill: #d2112f;
      }
    }
  }
}
