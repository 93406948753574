@import '../../../../../theme/main';

.folderPlots {
  display: flex;
  flex-direction: column;

  .plot {
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    height: 60px;
    padding: 10px;
    background-color: #f1f0f0;
    font-size: 14px;
    cursor: pointer;

    &.isSub {
      background-color: #dfdede;
      margin-left: 20px;
      width: calc(100% - 20px);
    }

    .plotIndex {
      padding: 2px;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      margin-right: 7px;
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .plotContent {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 26%;
      }

      > div {
        width: 20%;
      }
    }
  }
}
