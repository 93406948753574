@import '../../../theme/main';

.redDownloadButton {
  align-self: flex-end;
  @include flex-center-center;
  border-radius: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: $color-warning;
  padding: 0 15px;
  margin: 3px 0 3px 3px;
  height: 35px;
  width: 180px;
  border: none;
  text-decoration: none;
  color: $white;
  font-weight: 500;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background-color: $gray;
  }

  img,
  svg {
    height: 20px;
    width: 20px;
  }

  p {
    flex-grow: 1;
    color: $white;
  }
}
