@import '../../../../theme/main';

.multiselectPlotModal {
  @include flex-column-center-center;
  overflow: hidden;
  position: relative;
  height: 100%;
  // min-height: 300px;
  // max-height: 550px;
  background: #ffffff;
  color: $color-primary;
  border: none;

  > h3 {
    text-align: center;
  }

  .duplicatePlotContent {
    width: 100%;
    padding: 0 20px;

    h3 {
      font-size: 1.3em;
      margin-top: 10px;
      text-align: center;
    }

    button {
      @include cancel-btn;
      margin: auto;
      margin-top: 40px;
      font-size: 1.2em;
    }

    .duplicatePlotsContainer {
      width: 100%;
      padding: 10px 0 10px 20px;
      list-style-type: none;

      .duplicatePlotsRow {
        text-align: start;
        font-size: 0.9em;
        line-height: 10px;
        margin-bottom: 5px;

        .plotName {
          font-weight: 700;
        }
      }
    }

    .indications {
      font-style: italic;
      font-size: 0.9em;
    }
  }
  .title {
    align-self: flex-start;
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 20px;

    span {
      width: 20px;
      height: 20px;
      background: #4fd211;
      border-radius: 64px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      padding: 4px 7px;
    }
  }

  .multiselectPlotModalContent {
    width: 100%;
    // flex-grow: 1;

    .folderContainer {
      width: 100%;
      max-height: 420px;
      overflow: auto;
      overflow-x: hidden;

      .isSub {
        margin-left: 20px;
      }
    }
  }

  .folderRow {
    width: 100%;

    .folderHeader {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .checkIcon {
        padding: 4px 13px 4px 0px;
      }

      img {
        padding: 0;
        margin: 0;
        padding-right: 8px;
        width: 40px;
      }

      .name {
        text-align: left;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .buttonContainer {
    margin-top: 20px;
    @include flex-around-center;
    width: 100%;

    button {
      width: 40%;
    }

    .greenSubmitButton {
      @include green-submit-btn;
    }

    .cancelBtn {
      @include cancel-btn;
    }
  }

  .submittedResult {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tickIcon {
      fill: #75cc8b;
      font-size: xxx-large;
    }

    .resultTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      color: #4e55a2;
    }

    .greenSubmitBtn {
      @include green-submit-btn;
      margin-top: 40px;
    }

    .cancelBtn {
    }
  }

  .multiselectPlotModalError {
    @include flex-column-center-center;

    > p {
      line-height: 25px;
    }
    .buttonError {
      @include button-base;
      margin-top: 30px;
      width: 200px;
    }
  }
}
