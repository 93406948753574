@import '../../../../theme/main';

.login-modal {
  font-family: $primary-font;

  .MuiPaper-rounded {
    border-radius: 0;
  }
  .MuiDialogContent-root {
    border-radius: 0;

    .sign-in-modal-container {
      border: 6px solid $color-primary;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 50px 60px;
      position: relative;

      .title-pin {
        img {
          padding-top: 30px;
          width: 40px;
        }
      }

      h2 {
        font-weight: 600;
        font-size: 19px;
        padding: 15px 0px 20px;
        color: $color-primary;
      }

      .login-form-wrapper {
        width: 100%;

        .MuiFormControl-root {
          margin-bottom: 10px;
          input {
            padding: 10px 5px;
          }
        }
        .login-form {
          .remember-me {
            color: $color_primary;
            .MuiCheckbox-root {
              color: $color_primary;
            }
          }
          .forgot-password {
            color: $dark-red;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
          }
          .submit-btn {
            margin: 30px 0px;
            width: 100%;
            border: none;
            background: $color-primary;
            height: 48px;
            box-shadow: $button-shadow;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }

      .footer-wrapper {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-text {
          color: $light-text;
          font-size: 14px;
        }

        .right-text {
          color: $dark-red;
          font-size: 14px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
.error {
  margin: 0;
  color: $dark-red;
}
.forgot-password {
  text-align: center;
  color: #1890ff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
