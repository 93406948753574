@import '../../../../theme/main';

// button style
@mixin toolbar-btn-style {
  position: relative;
  margin: 0 5px 0 0;
  width: 59px;
  height: 53px;

  .iconContainer {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 13px 16px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-color: $white;

    &.cancelButton {
      background-color: $color-warning;

      &:hover {
        background-color: $color-warning;
      }
    }

    p.btnTooltip {
      margin-top: 0;
      margin-bottom: 0;
    }
    .btnTooltip {
      visibility: hidden;
      position: absolute;
      top: 63px;
      background: $white;
      color: $color-primary;
      display: flex;
      align-items: center;
      border-radius: 6px;
      font-size: 12px;
      width: auto;
      padding: 0 15px;
      height: 40px;
      white-space: nowrap;
      z-index: 10;
      box-shadow: 0 0 5px $black;
    }

    &:hover {
      background-color: #ddd;
      cursor: pointer;

      .btnTooltip {
        visibility: visible;
      }
    }

    &.isActive {
      background-color: $toolbar_active_bg;
    }

    &.isMultiplotStart {
      // padding: 14px 8px;
      img {
        height: 25px;
      }
    }

    &.isMultiplotSave {
      padding: 12px 10px;
      img {
        height: 30px;
      }
    }

    &.primaryBg {
      background-color: $color-primary;
    }

    &.isMeasureIcon {
      padding: 8px 0;
    }

    &.isAltimetryIcon {
      padding: 14px 8px;
      img {
        width: 43px;
        height: 25px;
      }
    }

    svg,
    img {
      height: 100%;

      &.notReady {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background-color: red;
      }
    }
  }

  .notification {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    background-color: #4fd211;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

.urbaToolbar {
  position: absolute;
  top: 33px;
  left: 51%;
  z-index: 2;
  @include toolbar_container;

  .urbaToolbarBtnContainer {
    @include toolbar-btn-style;
  }
}

.rightToolbar {
  position: absolute;
  right: 20px;
  top: 33px;

  .urbaToolbarBtnContainer {
    @include toolbar-btn-style;
    margin-bottom: 10px;

    .btnTooltip {
      margin-left: -100px;
    }
  }
}

.bellAlert {
  width: 14px;
  height: 14px;
}
