@import '../../theme/main';

.noCity,
.deniedAccess {
  text-align: center;
  margin: 60px 0;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 20px;
}
