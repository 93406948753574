@import "./../../../../../../../theme/main";

.construct-comment-block {
  .MuiFormControl-root {
    margin: 15px 0;

    .construct-comment {
      width: 100%;
      height: 160px !important;
      padding: 10px;
      background-color: white;
      border: 1px solid grey;
      border-radius: 4px;
    }
  }
}
