@import '../../../theme/main';

.progressBar {
  width: 100%;
  color: $color-primary;
  .progressBarContainer {
    margin: auto;

    .currentStepLabel {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 1.2em;
      text-align: center;
    }

    .progressBarContent {
      width: 100%;
      overflow: hidden;
      border: 1px solid black;
      border-radius: 15px;
      display: flex;
    }

    .stepInProgress {
      height: 100%;
      background-color: $color-primary;
      transition: width 0.2s ease-in-out;
    }

    .loadingAnimationContainer {
      height: 100%;

      .loadingAnimation {
        height: 100%;
        background-color: $color-font-link;
        animation-duration: 0.3s;
        animation-name: changewidth;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }
}

@keyframes changewidth {
  from {
    width: 0;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}
