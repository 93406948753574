@import '../../../theme/main';

.contactModalContent {
  height: 530px;
  max-width: 700px;
  overflow: hidden;

  .patternField {
    width: 250px;
    input {
      height: 25px;
    }
  }

  .text-field {
    margin: 20px;
    width: 30%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;

    th {
      padding-bottom: 10px;
    }

    td,
    th {
      border-bottom: 2px solid $background-grey;

      &:nth-child(1) {
        // name
        width: 30%;
      }
      &:nth-child(2) {
        // phone
        width: 10%;
      }
      &:nth-child(3) {
        // mail
        width: 30%;
      }
    }

    .row {
      &:hover {
        background-color: $background-grey;
        cursor: pointer;
      }

      td {
        padding: 5px;
        font-size: 0.9em;

        a {
          font-weight: 500;
          text-decoration: underline;
          color: #0000ee; //default link color
        }
      }
    }
  }
}
