@import '../../../theme/_main.scss';

.tab-agenda {
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
  }
  .tab-body {
    .row {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      td {
        border-bottom: none;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #334660;
      }
      .col {
        &-delete {
          @include flex-center-center;
          padding: 12px;
          text-align: right;
          svg {
            background: #4e55a2;
            color: #ffffff;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            padding: 8px;
            cursor: pointer;
          }
        }
        &-title {
          &:hover {
            background-color: rgba(224, 224, 224, 1);
            cursor: pointer;
          }
        }
        &-action {
          img {
            height: 22px;
            width: 22px;
          }
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 30px;
            color: #334660;
            margin-left: 3px;
          }
          div {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
