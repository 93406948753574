@import '../../theme/main';

.buildPermitsTableHeader {
  @include right-panel-modal-header;
  height: auto;

  h1 {
    margin: 0;
  }
  .form {
    margin-left: 10px;

    h3 {
      margin-bottom: 0;
      border-top: solid 1px;
      padding-top: 20px;
    }
    .search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 30px;
      font-size: 16px;
    }
    .filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      font-size: 16px;
    }
    .label {
      margin: 0;
      margin-right: 20px;
    }
    .inputField {
      border: none;
      background-color: $color-primary;
      box-shadow: none;
      width: 200px;
      border-radius: 60px;
      overflow: hidden;
    }
    .selectField {
      width: auto;
    }
  }
}

.popupMarkerContent {
}

.buildPermits {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  box-sizing: unset;
  z-index: 1000;
  text-align: center;
  line-height: 12px;
  border: 1px solid white;

  &.paMarker {
    background-color: green;
    /* border: 1px solid white; */
  }

  &.dpMarker {
    background-color: blue;
    /* border: 1px solid white; */
  }

  &.pcMarker {
    background-color: orange;
  }

  &.cancelled {
    height: 10px;
    width: 10px;
    color: red;
    border: 2px solid red;
    line-height: 10px;
  }

  &.recent {
    border: 2px solid greenyellow;
  }
}
