@import '../../../theme/main';

.delete-procedure-modal {
  .edit-procedure-modal-content {
    border: 6px solid $color-primary;
    color: #4e55a2;
    text-align: center;

    .title {
      margin-top: 50px;
      font-size: 20px;
      font-weight: 700;
    }

    .form-field {
      margin: 30px 0;
      font-size: 1rem;
    }

    .valid-btn {
      @include button-base;
      background-color: $dark-red;
      width: 50%;
      margin-bottom: 20px;
      border-color: $dark-red;
    }
  }
}
