@import '../../../theme/main';

.markerPopup {
  h3 {
  }

  button {
    @include button-base;
    width: 100%;
    height: 30px;
  }
}
