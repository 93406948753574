@import '../../../../theme/main';

.sectorSection {
  margin-bottom: 10px;

  .sectorContainer {
    .noElement {
      text-align: center;
    }
  }

  .sector {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .sectorHeader {
      position: relative;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      margin-top: 5px;
      border-radius: 5px;
      background-color: $white;
      box-shadow: 0 0 4px rgba(37, 38, 94, 0.1);

      &.subsector {
        background-color: #dfdede;
        margin-left: 20px;
        width: calc(100% - 20px);
      }
    }

    .index {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      border-radius: 50%;
      background-color: $color-primary;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      width: 20px;
      height: 20px;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      width: 294px;

      .title {
        color: $color-primary;
        margin: 0;
        margin-bottom: 2px;
      }

      .subCount {
        font-style: italic;
        font-size: 0.8em;
      }
    }
    .responsable {
      color: $color-primary;
      font-weight: 700;
      padding-right: 5px;
    }

    .iconContainer {
      display: none;
    }

    &:hover {
      .iconContainer {
        display: block;
        position: absolute;
        right: 0px;
        height: 60px;
      }
    }
  }

  .sectorContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
  }
}
