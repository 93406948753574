@import '../../../theme/main';

.announcement-modal {
  &-container {
    color: $color-primary;
    border: 6px solid $color-primary;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .xicon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      img {
        width: 20px;
      }
    }

    &-title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
      letter-spacing: 0.27px;
    }

    &-content {
      width: 100%;

      .item {
        background-color: $white;
        margin-top: 25px;
        padding: 10px 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        &-title {
          text-align: center;
          color: $dark-red;
        }
        &-content {
          margin-bottom: 30px;
          img {
            max-width: 250px;
          }
          table {
            border-collapse: collapse;
            width: 100%;

            td,
            th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }

            th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              background-color: $color-primary;
              color: white;
            }
          }
        }
        &-date {
          margin: 0;
          text-align: right;
        }
      }
    }
    button {
      border-color: $color-primary;
      background-color: $color-primary;
      cursor: pointer;
      border-radius: 2px;
      height: 40px;
      color: $white;
      font-size: 16px;
      font-weight: bold;
      font-family: $primary-font;
      margin-top: 10px;
      width: 100%;
    }
  }
}
