@import '../../../theme/variables';

.MuiDialog-root {
  .MuiPickersToolbarButton-toolbarBtn {
    width: fit-content !important;
    box-shadow: none !important;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    color: white;
    &:hover {
      color: #4e55a2;
    }
  }
  .MuiDialog-paperWidthMd.MuiDialog-paperScrollBody {
    border-radius: 10px;
    max-width: 800px;
    .intro-modal-container {
      padding: 18px 25px 0px 25px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      .xicon-wrapper {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
        img {
          vertical-align: middle;
        }
      }
      .content-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        img {
          margin: 53px auto 41px auto;
          max-width: 332px;
          @media screen and (max-width: 768px) {
            margin: 25px auto;
            max-width: 290px;
          }
        }
        .title-description {
          font-weight: normal;
          font-size: 1.1rem;
          line-height: 171%;
          text-align: center;
          color: $light-text;
          padding: 0 55px 30px 55px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          @media screen and (max-width: 768px) {
            padding: 0 10px 20px 10px;
            line-height: 22px;
          }
        }
        .list-text-wrapper {
          /*margin-bottom: 50px;
                    margin-left: 25px;*/
          //margin: 0px 30px 50px 25px;
          padding: 0 70px 30px 70px;
          @media screen and (max-width: 768px) {
            padding: 0 10px 20px 10px;
          }
          .list-items {
            display: -webkit-box;
            margin-bottom: 20px;
            .digit {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 22px;
              color: $white;
              border-radius: 50%;
              background: $color-primary;
              font-size: 0.8rem;
              margin-right: 25px;
            }
            .description {
              font-weight: 300;
              font-size: 1.2rem;
              line-height: 20px;
              color: $color-primary;
              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
                'Helvetica Neue', sans-serif;
              @media screen and (max-width: 768px) {
                width: 90%;
              }
            }
          }
        }
        .last-description {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          font-size: 1.1rem;
          text-align: center;
          color: $light-text;
          padding-top: 30px;
          @media screen and (max-width: 768px) {
            padding-top: 15px;
          }
        }
      }
      .button-wrapper {
        background-image: url(../../../assets//images/map-color.png);
        height: 170px;
        display: flex;
        padding: 20px 26px;
        width: 100%;
        background-size: calc(100% - 52px);
        background-repeat: no-repeat;
        background-position: center bottom 20px;
        @media screen and (max-width: 768px) {
          height: 110px;
          padding: 0;
        }
        .button {
          display: block;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          font-size: 16px;
          width: 100%;
          max-width: 503px;
          height: 48px;
          background: $color-primary;
          margin: auto;
          color: $white;
          border: none;
          cursor: pointer;
          outline: none;
          @media screen and (max-width: 768px) {
            margin: 20px auto;
          }
        }
      }
    }
  }
}
