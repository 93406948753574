@import '../../../theme/main';

.autocompleteContainer {
  position: relative;

  .lastNameChoiceContainer {
    width: 300px;
    height: 100px;
    background-color: $white;
    border: 1px solid $background-grey;
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 3px 0;
        cursor: pointer;

        &:hover {
          background-color: $background-grey;
        }
      }
    }
  }
}
