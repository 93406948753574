@import '../../../theme/main';

.markerBaseTooltip {
  border: 2px solid $color-primary;
  border-radius: 5px;

  &Content {
    margin: 0;
    text-align: center;
    color: $color-primary;
  }
}
