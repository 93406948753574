@import '../../../theme/main';

.favAndHistoric {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
  top: 185px;
  left: 450px;
  width: 350px;

  .favoriteContainer,
  .historyContainer {
    .row {
      @include flex-center-center;
      padding: 2px 5px;

      span {
        flex-grow: 1;
        cursor: pointer;

        &:hover {
          font-weight: 600;
        }
      }

      svg {
        height: 20px;
        width: 20px;
        padding-left: 5px;
        color: $color-primary;
      }
    }
  }

  .favoriteContainer {
    background-color: $body-background;
    svg {
      cursor: pointer;

      &:hover {
        color: $color-warning;
      }
    }
  }

  .historyContainer {
    svg {
      cursor: copy;
    }
    .row {
      .svgDisabled {
        color: $gray;
        cursor: not-allowed;
      }
    }
  }

  button {
    align-self: center;
    width: 90%;
    height: 25px;
    margin: 5px 0;
  }
}
