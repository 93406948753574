@import '../../../theme/main';

.genericModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(100, 100, 100, 0.6);
  z-index: 10000;

  .genericModal {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 0 10px 20px 10px;
    background-color: $modal-color-background;
    color: $color-primary;
    border-radius: 10px;
    min-width: 490px;
    max-width: 600px;
    width: 50%;
    z-index: 10001;

    &.extendWidth {
      max-width: 800px;
    }

    &.infoTheme {
      border: 4px solid $modal-color-info;

      .modalContainer {
        h2 {
          margin: 20px 0;
        }
        .content {
          .iconContainer {
            svg {
              background-color: $modal-color-info;
            }
          }
        }
        .buttonGrp {
          .buttonOk {
            background-color: $color-primary;
          }
        }
      }
    }

    &.confirmationTheme {
      border: 4px solid $modal-color-success;

      .modalContainer {
        .content {
          .iconContainer {
            svg {
              background-color: $modal-color-success;
            }
          }
        }
        .buttonGrp {
          .buttonOk {
            background-color: $modal-color-success;
          }
        }
      }
    }

    &.warningTheme {
      border: 4px solid $modal-color-warning;
      .modalContainer {
        .content {
          .iconContainer {
            svg {
              background-color: $modal-color-warning;
            }
          }
        }
        .buttonGrp {
          .buttonOk {
            background-color: $modal-color-warning;
          }
        }
      }
    }

    &.errorTheme {
      border: 4px solid $modal-color-error;
      .modalContainer {
        .content {
          .iconContainer {
            svg {
              background-color: $modal-color-error;
            }
          }
        }
        .buttonGrp {
          .buttonOk {
            background-color: $modal-color-error;
          }
        }
      }
    }

    .modalContainer {
      .topIcon {
        display: flex;
        justify-content: flex-end;

        svg {
          height: 35px;
          padding: 5px;
          cursor: pointer;
        }
      }

      img {
        width: 200px;
        display: block;
        margin: auto;
        padding-top: 20px;
      }

      h2 {
        text-align: center;
        margin: 10px 0 20px 0;
        font-size: 2em;
        font-weight: 700;
      }

      .content {
        @include flex-center-center;

        .iconContainer {
          @include flex-center-center;
          width: 30%;

          svg {
            color: white;
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 50%;
          }
        }

        .body {
          padding: 10px 20px;
          font-size: 1.1em;
          flex-grow: 1;

          // p {
          //   margin: 0 0 10px 0;
          // }
        }
      }

      .buttonGrp {
        margin: 30px 0 10px 0;
        @include flex-center-center;
        > div {
          @include button-base;
          @include flex-center-center;
          font-size: 1.3em;
          font-weight: 700;
          width: 40%;
          max-width: 250px;
          border: none;
          margin: 0 5px;

          &:active {
            box-shadow: none;
          }
        }

        .buttonCancel {
          background-color: $modal-color-shadow;
          font-weight: 400;
        }
      }
    }
  }
}
