.tab-password {
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
  }
  .tab-body {
    display: flex;
    justify-content: center;
    form {
      width: 270px;
    }
    .field {
      margin-bottom: 15px;
      .MuiFormControl-root {
        box-shadow: 0 0 3px 1px rgb(0 0 0 / 6%);
        border-color: #e4e6e8;
        background-color: #f1f4f8;
        .MuiOutlinedInput-root {
          border-radius: 5px;
          background: #ffffff;
          border: 1px solid #e4e6e8;
          &:hover {
            color: #4e55a2;
          }
          .MuiOutlinedInput-input {
            height: 30px;
            color: #4e55a2;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            background: transparent;
          }
        }
        .MuiInputLabel-outlined {
          color: #4e55a2;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          background: transparent;
        }
      }
    }
    button {
      background: #bac6f2;
      border-radius: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #4e55a2;
      border: none;
      width: 100%;
      padding: 15px 0px;
      cursor: pointer;
      margin-top: 5px;
      width: 270px;
    }
  }
}
