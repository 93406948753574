@import '../../../theme/main';

.folderSection {
  margin-bottom: 10px;

  .folderContainer {
    .noElement {
      text-align: center;
    }
  }
}
