@import '../../../../theme/main';

.right-panel-modal-body {
  .panel-content-body {
    .prospection-body-container {
      width: 100%;
      background-color: #fff;
      padding: 16px;
      margin: 15px 0;
      border-left: 8px solid $color-primary;
      border-radius: 5px;
      box-shadow: 0 4px 24px 0 rgb(37 38 94 / 10%);

      .container-header {
        display: flex;
        justify-content: center;
        align-items: center;

        .container-title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
          flex-grow: 1;
          cursor: pointer;
        }

        .icon {
          color: $color-primary;
          width: 30px;
          height: 30px;
          cursor: pointer;

          &.down {
            transform: rotate(180deg);
          }
        }
      }

      .body-content {
        .MuiSelect-selectMenu {
          line-height: 16px;
        }

        .MuiOutlinedInput-input {
          padding-top: 8px;
          // padding-bottom: 8px;
        }

        &.hidden {
          display: none;
        }

        .management-content {
          display: flex;

          > div {
            width: 50%;
          }

          .left-bloc {
            li {
              margin: 0;
            }
          }

          .plot-status-select,
          .responsable-select {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            label {
              width: 60%;
              display: flex;
              justify-content: flex-end;
              padding-right: 10px;
            }
          }
        }

        .mailbox-content {
          display: flex;
          margin: 5px 0;

          .mailbox-qty {
            width: 300px;
            margin-right: 50px;
          }
        }

        .sector-responsable-content {
          width: 300px;
        }

        .submit-block {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            width: 30%;
          }
        }
      }
    }

    .delete-button {
      background-color: #d2112f;
      border-color: #d2112f;
      width: 100%;
    }
  }
}
