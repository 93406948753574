@import '../../../theme/main';

.selectForm {
  display: flex;
  flex-direction: column;
}

.selectMenuItem {
  line-height: 1 !important;
  font-size: inherit !important;
  padding: 4px 6px !important;
}

.error {
  fieldset {
    border-color: $input-has-error-border !important;
  }
  input {
    background-color: $input-has-error-bkg;
  }
}
