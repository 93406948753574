@import '../../../theme/main.scss';

.tab-directory {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-inner {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 42px;
    color: #353b7d;
    margin-top: 0;
  }

  .tab-body {
    .icon {
      color: $color-primary;
      width: 30px;
      height: 30px;
      cursor: pointer;
      &.down {
        transform: rotate(180deg);
      }
    }
    .loader {
      margin: 20px;
    }

    .plot-study-row {
      // &:hover {
      //   background-color: $background-grey;
      //   cursor: pointer;
      // }
    }
    .read-more {
      display: flex;
      justify-content: center;
      letter-spacing: 2px;
      border: 1px solid $color-primary;
      border-radius: 7px;
      padding: 0 2px;
      margin: auto;
      width: 30px;
      height: 20px;
      cursor: pointer;
      font-size: 16px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .engine-row {
    td {
      border-bottom: none;
      padding: 0;
      text-align: left;
    }
  }
  .pagination-row {
    > td {
      border-bottom: none;
      padding: 0;
    }
  }

  .content-table-row {
    > td {
      padding: 0 0 10px 0;
    }
  }
}
