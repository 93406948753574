@import '../../../theme/variables';

.urba-env-content {
  display: flex;
  background-image: url('../../../assets/images/bg_right_pan.png');
  background-size: cover;
  background-position: center;

  .tabs-wrapper {
    display: flex;
    min-width: 112px;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 25%;
    padding: 24px 0;
  }

  .content-wrapper {
    padding: 24px 20px;
    flex-grow: 1;

    .plu-status {
      text-align: center;
      h4 {
        margin: 40px 0;
      }

      .status {
        font-weight: 700;
      }
    }
  }
}
