@import '../../../../theme/main';

.MuiDialogContent-root {
  padding: 0 !important;

  .modal-box-wrapper {
    display: flex;
    flex-direction: row;
    height: 660px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .hubspot {
      color: $color-primary;

      h2 {
        padding: 110px 0 40px 0;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        color: inherit;
        text-align: center;
      }

      p {
        margin-top: 40px;
        line-height: 18px;
        font-size: 16px;
      }

      .houses-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 90%;
          position: relative;
          bottom: -15px;
        }
      }
    }

    h2 {
      padding: 30px 0px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }

    .MuiInputBase-input {
      line-height: normal;
    }

    .modal-login-panel {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 50px 0px;
      width: 45%;
      background: #4f57a0;
      @media screen and (max-width: 768px) {
        width: auto;
      }

      .title-pin {
        img {
          padding-top: 40px;
          height: 118px;
        }
      }

      .login-form-wrapper {
        width: 100%;

        input {
          padding: 10px 0 10px 5px;
        }
        .login-form {
          .MuiFormControl-root {
            margin: 10px 0px;
            box-shadow: 0 0 3px 1px rgb(0 0 0 / 6%);
          }

          .remember-me {
            color: #fff;
            .MuiCheckbox-root {
              color: #fff;
            }
          }

          .forgot-password {
            font-size: 12px;
            text-align: center;

            a {
              margin: 0;
              color: #1890ff;
            }
          }

          .submit-btn {
            margin-top: 20px;
            width: 100%;
            border: none;
            background: $light-steel-blue;
            height: 48px;
            box-shadow: $button-shadow;
            color: $color-primary;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .caption-text {
            font-size: 12px;
            text-align: center;
            color: $white;
            padding: 12px;
            .link {
              color: #1890ff;
            }
          }
        }
      }
    }

    .modal-right-panel {
      padding: 50px 50px;
      width: 55%;
      background: $ghost-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: auto;
      }

      .login-form-wrapper {
        width: 100%;

        .head-text {
          text-align: center;
          color: $color-primary;
          font-size: 14px;
          font-weight: 700;
          line-height: 23px;
          margin: 20px 0;
        }

        .login-form {
          .inline-form-fields {
            display: flex;
          }

          .form-field {
            margin: 2px 0px;
            padding: 3px;
          }

          .houses-img-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 90%;
              position: relative;
              bottom: -15px;
            }
          }
        }
      }

      .submit-btn {
        //margin-bottom: 20px;
        position: relative;
        z-index: 2;
        width: 100%;
        border: none;
        background: $color-primary;
        height: 48px;
        box-shadow: $button-shadow;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .signup-demand-response {
        color: #4e55a2;
        font-size: 16px;

        h2 {
          font-weight: 700;
          text-align: center;
        }

        .link-for-demo {
          line-height: 1.5;

          a {
            display: inline-block;
            padding-top: 20px;
            color: $color-font-link;
            text-decoration: underline;
          }
        }
        &-icon-container {
          @include flex-center-center;
          width: 100%;
          margin: 40px 0 80px 0;

          svg {
            background-color: $status-success;
            width: 60px;
            height: 60px;
            padding: 10px;
            border-radius: 50%;
            color: $white;
          }

          &.red {
            svg {
              background-color: $color-warning;
            }
          }
        }

        .signup-demand-response .connecter-btn.link-btn {
          display: block;
          margin-top: 50px;
          font-size: 16px;
        }
      }
    }
  }
}
