@import '../../../theme/main';

.multiPDFContent {
  @include flex-center-center;
  p {
    margin: 0;
    padding: 0 5px;
  }

  .loader {
    display: block;
    flex-grow: unset;
    width: 20px;

    svg {
      color: $white;
    }
  }
}
