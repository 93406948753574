@import '../../../theme/main';

.rightPanelModal {
  font-family: $regular-fonts;
  position: absolute;
  top: 0;
  z-index: 999;
  height: 100vh;
  width: 550px;
  background-color: $white;
  border-left: 1px solid $color-primary;
  right: -549px;
  margin-top: 55px;

  &.displayed {
    right: 0;
  }
  p {
    margin: 5px 0;
  }
  h3 {
    margin: 10px 0;
  }

  &Header {
    @include flex-column-center-center;
    color: $white;
    background-color: $color-primary;
    height: 45px;
    h2 {
      color: $white;
    }
  }

  &Body {
    position: relative;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 20px;

    &.hidden {
      visibility: hidden;
      height: 0;
      overflow: hidden;
    }
    h3 {
      color: $color-primary;
    }
    textarea {
      color: #354052;
      font-size: 14px;
      font-weight: 300;
      resize: none;

      &.error {
        border: 1px solid red;
        outline: 1px solid red;
        background-color: #ffecfc;
      }
    }

    .rowInput {
      @include flex-center-center;
      padding-left: 20px;
      width: 100%;
      min-height: 43px;
      p {
        min-width: 165px;
        margin: 0;
      }
    }
    .rowIcon {
      display: flex;
      align-items: center;
      height: 43px;
      > img {
        padding-right: 20px;
        width: 50px;
      }
      .rowInput {
        border-left: 2px #679cc6 solid;
        height: 100%;
      }
    }
    .columnInput {
      margin-bottom: 5px;
      .spacing {
        margin-bottom: 5px;
      }
    }
    .commentContainer {
      background-color: #726fad1a;
      padding: 5px 10px;
      margin: 5px 0;

      p {
        span {
          font-size: 0.8em;
          font-style: italic;
        }
      }
    }
    .checkboxContainer {
      display: flex;
      flex-wrap: wrap;
    }
    .inputForm {
      display: flex;
      align-items: center;
      width: 100%;
      svg {
        margin-left: 5px;
      }
      .tick {
        &Green {
          fill: #2ed753;
        }
        &Gray {
          fill: #e6e6e6;
        }
      }
    }
  }

  .arrowWrapper {
    position: fixed;
    z-index: 1;
    margin-top: 95px;
    cursor: pointer;
    .arrow {
      height: 30px;
      position: absolute;
      left: -62px;
      rotate: 90deg;
    }
  }

  .progressBarContainer {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
  }
}
