@import '../../theme/main';

.switchDomTom {
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  h3 {
    color: $color-primary;
    text-align: left;
  }

  .switchDomTomContent {
    .label {
      font-size: 1.1em;
      margin: 0 20px;

      &.selected {
        color: $color-primary;
        font-weight: 700;
      }
    }
  }
}
