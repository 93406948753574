@import '../../theme/main';

.dashboard-tabs {
  margin: 0 70px;
  display: flex;
  color: $color-primary;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }
  }

  .dashboard-tab {
    height: 50px;

    &.selected {
      border-bottom: 2px solid $color-primary;
    }
  }
}
