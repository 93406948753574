@import '../../../theme/main';

.error {
  fieldset {
    border-color: $input-has-error-border !important;
  }
  input {
    background-color: $input-has-error-bkg;
  }
}
