@import '../../../theme/main';

.buildPermitsLegend {
  @include environment-container;

  .noCity {
    text-align: center;
    margin-top: 60px;
    font-size: 1.1em;
    line-height: 20px;
  }

  .displayPermits {
    span:first-child {
      padding: 2px;
    }
    span:last-child {
      font-size: 14px;
      line-height: 1;
    }
  }

  .markerLegend {
    margin-left: 4px;
    font-size: 14px;

    .filtersTitle {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
      font-style: italic;
    }
    ul {
      padding: 0;
      margin-top: 10px;
      margin-bottom: 25px;

      li {
        display: flex;
        align-items: center;

        > p {
          margin: 0 0 0 10px;
          line-height: 12px;
        }

        .buildPermis {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          box-sizing: unset;

          &.paMarker {
            background-color: green;
          }

          &.dpMarker {
            background-color: blue;
          }

          &.pcMarker {
            background-color: orange;
          }

          &.recentLegend {
            height: 12px;
            width: 12px;
            border: 2px solid greenyellow;
          }
          &.cancelled {
            height: 12px;
            width: 12px;
            color: red;
            border: 2px solid red;
            line-height: 10px;
            text-align: center;
          }
        }

        .housingFilterContainer {
          p {
            margin: 10px 0;
          }
          .housingFilter {
            @include flex-center-center;
            span {
              margin: 0 5px;
            }
            input {
              height: 14px;
              // only for displaying +- buttons on type number
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .tableButton {
    width: 100%;
    font-size: 13px;
  }
}
