@import '../../theme/main';

.filter-sort-bloc {
  height: 60px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  position: relative;

  .generic-search {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;

    .MuiInputBase-input {
      height: 22px;
    }

    .input-research {
      margin-right: 10px;
    }

    .research-btn {
      width: 150px;
    }
  }

  .right-bloc {
    display: flex;
    position: absolute;
    width: 234px;
    right: 0;
    text-align: start;

    .sort-select {
      flex-grow: 1;
    }

    .sort-buttons {
      display: flex;
      flex-direction: column;
      width: 30px;
      padding: 0 0 0 10px;

      .icon {
        height: 20px;
        background-color: $light-gray-bg;
        color: $gray;
        width: 100%;
        cursor: pointer;

        &.selected {
          color: $black;
        }

        &.desc {
          transform: rotate(180deg);
        }
      }
    }
  }
}
