@import '../../theme/main';

.buildCaracteristicsLegend {
  @include environment-container;

  h4,
  h5 {
    text-align: center;
    margin: 0;
  }

  .sectionTitle {
    width: 100%;
    background-color: $color-primary;
    color: $white;
    margin: 0;
    padding: 4px;
  }
}
