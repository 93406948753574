@import '../../../theme/main';

.right-bottom-buttons {
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: absolute;
  right: 20px;
  bottom: 10px;
  z-index: 550;

  &.external-pdf-errial {
    right: 560px;
  }

  .satellite-buttons {
    .buttons {
      display: flex;

      button {
        width: 70px;
        height: 40px;
        line-height: 40px;
        border-radius: 0px;
      }

      .left-btn {
        background: $white;
        color: $color-primary;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        &.selected {
          background: $color-primary;
          color: $white;
        }
      }

      .right-btn {
        background: $white;
        color: $color-primary;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;

        &.selected {
          background: $color-primary;
          color: $white;
        }
      }
    }
  }

  .zoom-buttons {
    .buttons {
      display: flex;
      margin-bottom: 10px;

      button {
        width: 70px;
        height: 40px;
        line-height: 40px;
        border-radius: 0px;
        font-size: 22px;
      }

      .left-btn {
        background: $color-primary;
        color: $white;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }

      .right-btn {
        background: $white;
        color: $color-primary;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .cadastre-buttons {
    margin-right: 20px;
    .Mui-disabled {
      opacity: 0.5;
    }

    button {
      width: 160px;
      height: 40px;
      line-height: 40px;
    }

    .btn {
      background: $white;
      color: $color-primary;
      border-radius: 5px;

      &.selected {
        background: $color-primary;
        color: $white;
      }
    }
  }

  .orpi-button {
    button {
      width: 160px;
      height: 40px;
      line-height: 40px;
    }

    .btn {
      background: $white;
      color: $color-primary;
      border-radius: 5px;

      &.selected {
        background: $color-primary;
        color: $white;
      }
    }
  }
}
