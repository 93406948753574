@import '../../../theme/main';

@mixin section-container {
  padding: 10px 16px;
  background: $white;
  border-radius: 5px;
  box-shadow: $box-shadow;
  border-left: 8px solid $color-primary;
  margin-bottom: 12px;

  .header {
    @include flex-between-center;
    color: $color-primary;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.39px;
    line-height: 20px;
    margin-bottom: 10px;

    .icon {
      color: $color-primary;
      width: 30px;
      height: 30px;
      cursor: pointer;

      &.down {
        transform: rotate(180deg);
      }
    }
  }

  .hidden {
    display: none;
  }
}

@mixin two-inputs-in-a-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .field {
    width: 50%;

    .hasError {
      fieldset {
        border-color: $input-has-error-border !important;
      }
      input {
        background-color: $input-has-error-bkg;
      }
    }

    .label {
      &.isLabelHover {
        @include flex-start-center;
      }

      font-size: 14px;
      margin-bottom: 2px;
      svg {
        cursor: help;
        margin-left: 5px;
        width: 13px;
        height: 13px;
      }
      span {
        font-size: 14px;
      }
    }

    input {
      line-height: 25px;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}

.advancedPlotSearchContainer {
  padding: 10px;
  background-image: url('../../../assets/images/bg_right_pan.png');
  background-size: cover;
  background-position: center;

  .formContainer {
    .blocContainer {
      @include section-container;
    }

    .zoneContainer {
      .zoneContent {
        @include two-inputs-in-a-row;
      }
    }

    .plotCalculationContainer {
      .plotCalculationContent {
        .checkContainer {
          display: flex;
          align-items: center;

          .checkLabelContainer {
            p {
              margin: 0;
              padding: 0;
            }
            .checkLabel {
              font-size: 14px;
              margin-bottom: 2px;
            }
            .checkLabelSub {
              font-size: 11px;
              font-style: italic;
            }
          }
        }

        .radioContainer {
          .radioLabel {
            span {
              font-size: 14px;
            }
          }
        }

        .inputContainer {
          @include two-inputs-in-a-row;
        }
      }
    }

    .ownerResearch {
      hr {
        margin: 20px 0;
      }

      .checkContainer {
        .checkLabel {
          span {
            font-size: 14px;
          }
        }
      }
      .label {
        font-size: 14px;
        margin-bottom: 10px;

        span {
          font-size: 14px;
        }
      }
      .field {
        margin-bottom: 10px;
        .label {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .hasError {
          fieldset {
            border-color: $input-has-error-border !important;
          }
          input {
            background-color: $input-has-error-bkg;
          }
        }
      }
      .radioContainer {
        margin-bottom: 10px;

        .radioGroup {
          justify-content: space-between;

          .radioLabel {
            margin-right: 5px;
            text-align: center;

            span {
              font-size: 14px;
              max-width: 120px;
              line-height: 1;
            }
            .radio {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .buildingContainer {
      .buildingContent {
        display: flex;
        flex-direction: column;

        .selectField {
          width: 50%;
          margin-bottom: 10px;
          padding-right: 5px;
        }

        .inputContainer {
          @include two-inputs-in-a-row;
        }
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: space-between;

      button {
        width: 44%;
        margin-right: 5px;
      }

      &.noPrint {
        button {
          width: 48%;
        }
      }
      .resetBtn {
        @include button-base-red;
      }

      .submitBtn {
        @include button-base;
      }

      .btnDisabled {
        cursor: not-allowed !important;
      }

      .printIconContainer {
        position: relative;
        height: 40px;
        width: 40px;
        padding: 5px;
        border-radius: 2px;
        z-index: 15;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        .printTooltip {
          position: absolute;
          top: -43px;
          left: -163px;
          width: 200px;
          display: none;
          background-color: $white;
          padding: 3px 5px;
          color: $color-primary;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          z-index: 20;
        }

        &:hover {
          background-color: #ddd;
          cursor: pointer;

          .printTooltip {
            display: block;
          }
        }
        svg {
          height: 100%;
          color: $color-primary;
        }
      }
      &:not(.btnDisabled) {
        button {
          &:hover {
            font-weight: 700;
            box-shadow: 0 0 5px $color-primary;
          }
        }

        .resetBtn {
          @include button-base-red;

          &:hover {
            box-shadow: 0 0 5px $color-warning;
          }
        }
      }
    }
  }
}
