@import '../../../theme/main';

.connectionProcess {
  width: 80%;
  margin: auto;
  h3 {
    margin: 20px 0;
    text-align: center;
    padding-bottom: 20px;
  }

  p {
    text-align: center;
  }

  .userProcess {
    padding-bottom: 20px;
    .hiUser {
      text-align: center;
      font-size: 1.5em;

      span {
        font-weight: 700;
        font-style: italic;
      }
    }
  }

  .companyProcess {
    p {
      padding-bottom: 10px;
      font-size: 1.2em;
      span {
        font-weight: 700;
      }
    }
    .backToHome {
      font-weight: 500;
      font-size: 1.1em;
      font-style: italic;
    }
  }

  .authError {
    border: 1px solid $modal-color-info;
    box-shadow: 0px 0px 5px $modal-color-info;
    padding: 20px 10px;
    color: $modal-color-info;

    p {
      padding: 0;
      color: $light-black;
      font-size: 1em;
      font-weight: 600;
    }

    a {
      text-align: center;
      display: block;
    }

    button {
      @include button-base;
      display: block;
      // background-color: $color-warning;
      color: $white;
      width: 40%;
      margin: 20px auto 0 auto;
    }
  }
}
