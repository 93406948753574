@import '../../theme/main';

.mobileAppContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mobileAppDownload {
  @include flex-center-center;
  flex-direction: column;
  position: absolute;
  z-index: 1400;
  margin: 0 5px;
  bottom: 10px;
  max-width: 500px;
  border-radius: 20px;
  background-color: $background-grey;
  margin-bottom: 25px;

  svg {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 30px;
    height: 30px;
  }

  h2 {
    margin: 0;
  }

  &Content {
    @include flex-center-center;
    flex-direction: column;
    padding: 17px 20px;

    @media only screen and (max-width: 415px) {
      padding: 17px 10px;
    }
    p {
      margin-top: 0;
      text-align: center;
      width: 250px;
    }
    div {
      display: flex;
      justify-content: center;

      a {
        margin: 0 10px;
      }
    }
  }
}
