@import '../../theme/variables';

.home-container {
  overflow: hidden;
  position: relative;

  height: 100vh;
  // height: 100%;
  // scroll-behavior: smooth;
  // .urbn-loader-wrapper {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  //   background-color: hsla(120, 13%, 10%, 0.7);
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   z-index: 10000;
  // }
}
