@import '../../theme/main';

.addressInputContainer {
  display: flex;

  .addressInput {
    flex-grow: 1;

    &.rightMargin {
      margin-right: 10px;
    }
  }
}
.predictionsContainer {
  position: absolute;
  background: $divider;
  width: 100%;
  max-width: 370px;
  margin: 5px 0 15px 0;
  padding: 5px 0 10px 0;
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;
  display: none;

  &.visible {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 15px;
    width: 100%;

    li {
      width: 100%;
      cursor: pointer;
    }

    .predictionList {
      width: 100%;

      h3 {
        background-color: $light-steel-blue;
        font-size: 1.1em;
        margin: 5px 0;
        padding: 5px;
      }

      .predictionRow {
        margin: 0;
        padding: 2px 0 2px 5px;

        &.focused,
        &:hover {
          background-color: $blue-select-prediction;
        }
      }
    }
  }
}
